<template>
  <div class="tenantInventoryView">
    <el-card>
      <el-form class="searchForm" inline>
        <div class="pageTypeBox">
          <el-radio-group v-model="pageType" size="small" @change="changePageType">
            <el-radio-button v-for="(item) in pageTypeItems" :key="item.key" :label="item.key">{{ item.label }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-form-item>
          <el-autocomplete v-model="ownerName" size="small" ref="ownerNameInput"
            placeholder="货主编号或名称" class="fullWidth" prefix-icon="el-icon-search"
            :fetch-suggestions="ownerSuggestion" @select="(v) => ownerChanged(v)"
            @change="(v) => ownerChanged(v)" @blur="resetSearchText('owner')">
            <i v-if="ownerName !== ''" slot="suffix" class="el-input__icon show-on-focus el-icon-error" @click="removeSearchText('owner')"></i>
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <tree-select-box v-model="searchForm.productCategoryId" :options="productCategoryList" placeholder="商品分类"></tree-select-box>
        </el-form-item>
        <template>
          <el-form-item>
            <tree-select-box v-model="searchForm.teaCategoryId" :options="teaCategoryList" placeholder="茶叶品类"></tree-select-box>
          </el-form-item>
        </template>
        <el-form-item>
          <tree-select-box v-model="searchForm.packTypeId" :options="packTypeList" placeholder="包装分类"></tree-select-box>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.keyWord" placeholder="请输入搜索关键词" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchDataList()" size="small" type="primary">搜索</el-button>
          <el-button size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;" border @sort-change="sort_change" show-summary :summary-method="getSummaries">
        <el-table-column header-align="center" width="133" align="center" prop="code" label="规格编号"></el-table-column>
        <el-table-column header-align="center" align="center" prop="ownerName" label="货主"></el-table-column>
        <el-table-column header-align="center" align="center" prop="name" label="商品名称"></el-table-column>
        <el-table-column header-align="center" align="center" prop="spuTypeName" label="商品类型"></el-table-column>
        <el-table-column header-align="center" align="center" prop="productInventory" label="供应商">
          <template slot-scope="scope">
            <div v-if="scope.row.supplierList.length > 0">{{supplierListInfo(scope.row.supplierList)}}</div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="specification" label="商品规格"></el-table-column>
        <el-table-column header-align="center" align="center" prop="productCategoryName" label="商品分类"></el-table-column>
        <el-table-column header-align="center" align="center" prop="teaCategoryName" label="茶叶品类"></el-table-column>
        <el-table-column header-align="center" align="center" prop="packTypeName" label="包装分类"></el-table-column>
        <el-table-column header-align="center" align="center" label="等级">
          <template slot-scope="scope">
            <div>{{scope.row.levelName}}<span v-show="scope.row.levelStandardName">{{scope.row.levelStandardName}}</span></div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="unitName" label="基本单位"></el-table-column>
        <el-table-column header-align="center" width="133" align="center" prop="totalEnterStockQuantity" label="总入库数量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" width="133" align="center" prop="totalLeaveStockQuantity" label="总出库数量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" width="133" align="center" prop="quantity" label="库存数量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" width="133" align="center" prop="availableQuantity" label="可用库存量" sortable="custom"></el-table-column>
      </el-table>
      <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
        :total="total" layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import treeSelectBox from '@/components/treeSelectBox'

export default {
  name: 'tenantInventoryView',
  components: { treeSelectBox },
  data () {
    return {
      pageType: 0,
      pageTypeItems: [
        { key: 0, label: '全部' },
        { key: 1, label: '产成品' },
        { key: 2, label: '散茶' },
        { key: 3, label: '准成品' },
        { key: 4, label: '包装物料' }
      ],
      ownerName: '',
      ownerNameOld: '',
      teaCategoryList: [],
      productCategoryList: [],
      packTypeList: [],
      searchForm: {
        productCategoryId: null,
        teaCategoryId: null,
        packTypeId: '',
        ownerId: null,
        keyWord: '',
        order: '',
        orderField: ''
      },
      tenantId: '',
      tableLoading: false,
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0
    }
  },
  activated () {
    let { id } = this.$route.query
    this.tenantId = id
    this.getProductCategoryTree()
    this.getTeaCategoryTree()
    this.getPackTypeTree()
    this.getDataList()
  },
  computed: {
    supplierListInfo () {
      return (arr) => {
        return arr.map((v) => v.name).join('、')
      }
    }
  },
  methods: {
    ...mapActions('reportInventory', ['getOwnerList',]),
    getDataList(p, s) {
      if (!p) p = this.currentPage
      if (!s) s = this.pageSize
      this.tableLoading = true
      this.$http.get('/statistics/tenantInventory/inventoryDetail', {
        params: {
          page: p,
          limit: s,
          spuTypeId: this.pageType,
          tenantId: this.tenantId,
          ...this.searchForm
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        if (code === 0 && data) {
          let { records, current, total} = data
          this.currentPage = current * 1
          this.total = total * 1
          this.tableData = records && records.length ? records : []
        }
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.tableLoading = false
      })
    },
    // 获取茶叶品类
    getTeaCategoryTree () {
      this.$http.get('/teaCategory/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(data)
        if (code === 0) {
          this.teaCategoryList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 获取商品分类
    getProductCategoryTree () {
      this.$http.get('/inventory/getProductCategoryAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(data)
        if (code === 0) {
          this.productCategoryList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 获取商品分类
    getPackTypeTree () {
      this.$http.get('/statistics/tenantInventory/packType/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(data)
        if (code === 0) {
          this.packTypeList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 货主下拉框
    ownerSuggestion (keyWord, callback) {
      this.getOwnerList({
          keyWord,
          page: 1,
          limit: 20
      }).then((res) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let items = data.records.map((v) => {
            return {
              id: v.id,
              value: v.name
            }
          })
          callback(items)
        } else {
          this.$message.error(msg)
        }
      })
    },
    ownerChanged (item) {
      let oldId = this.searchForm.ownerId
      if (typeof item === 'string') {
        if (oldId && item !== this.ownerNameOld) {
          this.$set(this, 'ownerName', this.ownerNameOld)
        }
      } else {
        this.$set(this.searchForm, 'ownerId', item.id)
        this.ownerNameOld = item.value
      }
    },
    removeSearchText (name) {
      this.$set(this, name + 'Name', '')
      // this.$set(this, name + 'NameOld', '')
      // this.$set(this.dataForm, name + 'Id', null)
      this.$nextTick(() => {
        this.$refs[name + 'NameInput'].focus()
      })
    },
    resetSearchText (name) {
      if (this.searchForm[name + 'Id'] && this[name + 'NameOld'] !== '') {
        this[name + 'Name'] = this[name + 'NameOld']
      } else if (!this.searchForm[name + 'Id']) {
        this[name + 'Name'] = this[name + 'NameOld'] = ''
      }
    },
    changePageType () {
      this.resetSearch()
    },
    getSupplierNames (items) {
      let ret = items.map((item) => item.name)
      return ret.join('、')
    },
    sizeChangeHandle(pageSize) {
      this.pageSize = pageSize
      this.currentPage = 1
      this.getDataList()
    },
    currentChangeHandle(currentPage) {
      this.currentPage = currentPage
      this.getDataList()
    },
    searchDataList () {
      this.getDataList(1)
    },
    resetSearch () {
      let form = this.$options.data().searchForm
      this.$set(this, 'searchForm', form)
      this.ownerName = this.ownerNameOld = ''
      this.$nextTick(() => {
        this.getDataList(1)
      })
    },
    sort_change (column) { // 自定义排序
      if (column.order === 'descending') { // 如果点击降序按钮
        this.searchForm.order = 'desc'
        this.searchForm.orderField = column.prop
      } else if (column.order === 'ascending') {
        this.searchForm.order = 'asc'
        this.searchForm.orderField = column.prop
      } else {
        this.$set(this.searchForm, 'order', '')
        this.$set(this.searchForm, 'orderField', '')
      }
      this.getDataList()
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let arr = ['总入库数量', '总出库数量', '库存数量', '可用库存量']
        if (arr.includes(column.label)) {
          const values = data.map(item => Number(item[column.property]))
          let totalNum = 0
          totalNum = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = totalNum
        }
        if (column.label === '操作') {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
.tenantInventoryView {
  .searchForm {
    margin-bottom: -18px;
  }
  .pageTypeBox {
    display: inline-block;
    margin-right: 10px;
  }
  .el-pagination {
    margin-top: 6px;
  }
  .el-table {
    margin-top: 16px;
  }
}
</style>
