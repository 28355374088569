<template>
  <div>
    <el-popover v-model="treeVisible" ref="selectTreePopover" popper-class="treeBox" placement="bottom-start" trigger="click">
      <el-tree
        :data="treeData"
        :props="treeProps"
        node-key="id"
        ref="selectTree"
        :highlight-current="true"
        :expand-on-click-node="false"
        accordion
        @current-change="selectTreeChanged">
      </el-tree>
    </el-popover>
    <el-input v-model="itemName" size="small" class="treeCursor" v-popover:selectTreePopover readonly :placeholder="placeholder">
      <i v-if="value !== null" slot="suffix" @click.stop="resetDefault()" class="treeCursorIcon el-input__icon el-icon-circle-close"></i>
    </el-input>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'treeSelectBox',
  props: {
    value: {
      type: [Number, String]
    },
    options: {
      required: true,
      type: Array
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    treeProps: {
      type: Object,
      default: () => {
        return { label: 'name', children: 'children' }
      }
    }
  },
  data () {
    return {
      treeVisible: false,
      itemList: [],
      treeData: [],
      itemName: ''
    }
  },
  watch: {
    'itemName': function (val) {
      this.$emit('update:name', val)
    },
    'options': function (val) {
      this.initOptions(val)
    },
    'value': function (val) {
      if (!val) {
        this.$set(this, 'itemName', '')
        return
      }
      let item = this.itemList.find((v) => v.id === val)
      if (item) {
        let names = this.getTreeItemName(item)
        this.$set(this, 'itemName', names.join(' / '))
      } else {
        this.resetDefault()
      }
    }
  },
  computed: {
    val: {
      get () {
        return this.value
      },
      set (v) {
        // console.log('update', v)
        this.$emit('input', v)
      }
    }
  },
  mounted () {
    this.initOptions(this.options)
    this.$nextTick(() => {
      this.changeSelectBoxWidth()
    })
  },
  methods: {
    changeSelectBoxWidth () {
      let $pop = this.$refs.selectTreePopover
      let $input = $pop.$refs.reference
      let $popper = $pop.$refs.popper
      // console.log([$input, $popper])
      if ($input) {
        let width = $input.getBoundingClientRect().width
        if ($popper) {
          $popper.style.setProperty('width', `${width}px`)
        }
      }
    },
    resetDefault () {
      this.$set(this, 'itemName', '')
      this.$set(this, 'val', null)
      this.treeVisible = false
    },
    selectTreeChanged (item) {
      this.$set(this, 'val', item.id)
      let names = this.getTreeItemName(item)
      this.$set(this, 'itemName', names.join(' / '))
      this.treeVisible = false
    },
    initOptions (val) {
      let items = _.cloneDeep(val)
      this.$set(this, 'itemList', items)
      let treeData = this.parseTreeNode(items)
      this.$set(this, 'treeData', treeData)
    },
    getTreeItemName (item) {
      if (item.pid === '0') {
        return [item.name]
      } else {
        let pItem = this.itemList.find((v) => v.id === item.pid)
        let names = this.getTreeItemName(pItem)
        names.push(item.name)
        return names
      }
    },
    parseTreeNode (arr) {
      if (!arr.length) return []
      let group = _.groupBy(arr, (v) => (v.pid + ''))
      // console.log(group)
      let conn = function (v, c) {
        let r = _.cloneDeep(v)
        r.forEach((item) => {
          let id = item.id
          if (c) {
            item.parentName = c.name
          }
          let children = group[id + '']
          if (Array.isArray(children) && children.length) {
            item.children = conn(children, item)
          } else {
            item.isLeaf = true
            item.children = undefined
          }
        })
        return r
      }
      let len = Object.keys(group).length
      if (len > 1) {
        let ret = conn(group['0'])
        return ret
      } else if (len === 1) {
        let key = Object.keys(group)[0]
        return group[key]
      } else {
        return []
      }
    },
  }
}
</script>
<style lang="scss">
  .treeCursor {
    .treeCursorIcon {
      display: none;
    }

    &:hover .treeCursorIcon {
      display: block;
    }

    >* {
      cursor: pointer;
    }
  }
  .treeBox {
    max-height: 300px;
    overflow: auto;
  }
</style>